/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomSpinner from '../../../components/CustomSpinner';
import { downloadDocumentRequest, getAllDocuments, getDocument} from '../../../services/HRServices/HRDocumentsService';
import CustomButton from '../../../components/CustomButton';
import { OnboardingDocumentsReadOnly, taxDocumentDictionary } from '../../../tools';
import QuickTable from '../../../components/QuickTable';
import moment from 'moment';
import DocumentRequestDetails from './DocumentRequestDetails';
import PDFMerger from 'pdf-merger-js/browser';

export default function HRUserDocuments({selectedCompanyUser}) {
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentRequests, setDocumentRequests] = useState([]);
    const [selectedDocumentRequest, setSelectedDocumentRequest] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState('');

    const [isDownloading, setIsDownloading] = useState(undefined);
    const [downloadingBundle, setDownloadingBundle] = useState(undefined)

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => { 
        setIsLoading(true);
        const response = await getAllDocuments(selectedCompanyUser.companyUserUid);
        if (response.status == '200') {
            setDocuments(response.documents);
            setDocumentRequests(response.documentRequests);
        }
        setIsLoading(false);
    }

    const handleDownloadDocument = async (uid) => {
        setIsDownloading(uid);
        const response = await getDocument('employeeDocument', uid);
        if (response.status == '200') {
            const fetchResponse = await fetch(response.document.base64);
            const blob = await fetchResponse.blob();
            const elem = window.document.createElement('a');
            elem.target = '_blank';
            elem.href = window.URL.createObjectURL(blob);
            elem.download = response.document.documentName;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        setIsDownloading(undefined);
    }

    const handleDownloadDocumentRequest = async (documentRequest) => {
        setDownloadingBundle(documentRequest.uid);
        const response = await downloadDocumentRequest(documentRequest.uid);
        if (response.status == '200') {
            const merger = new PDFMerger();

            const sortedDocumentTypes = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'f8850'].filter(d => Object.keys(response.documents).includes(d));

            for (let i = 0; i < sortedDocumentTypes.length; i++) {
                const document = response.documents[sortedDocumentTypes[i]];
                if (document.base64) {
                    const fetchResponse = await fetch(document.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }
    
            const mergedPdf = await merger.saveAsBlob();
    
            const elem = window.document.createElement('a');
            elem.target = '_blank';
            elem.href = window.URL.createObjectURL(mergedPdf);
            elem.download = `${selectedCompanyUser?.firstName ?? ''} ${selectedCompanyUser?.lastName ?? ''} Document Request ${moment(documentRequest.dateCreated).format('MMM D, YYYY')}`;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
        setDownloadingBundle(undefined);
    }

    const handleRemoveEdDocument = (documentRequestIdentifier, documentType) => {
        const newArray = Array.from(documentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        delete request.documents[documentType];
        setDocumentRequests(newArray);
    }

    const handleSetEDDocumentIncomplete = (documentRequestIdentifier, documentType) => {
        const newArray = Array.from(documentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        request.documents[documentType].employeeCompleted = null;
        setDocumentRequests(newArray);
    }


    const filteredDocuments = documents.filter((doc) => {
        return doc.documentName.toLowerCase().includes(search.toLowerCase());
    });

    const documentRows = filteredDocuments.map((doc) => {
        return (
            <tr key={doc.uid}>
                <td>{doc.documentName}</td>
                <td>{doc.documentType}</td>
                <td style={{whiteSpace: 'nowrap'}}>{moment(doc.timestamp).format('MMM D, YYYY [at] h:mm A')}</td>
                <td>
                    <CustomButton style={{padding: '0px 6px 0px 6px'}} label='Download' isLoading={isDownloading == doc.uid} onClick={() => {handleDownloadDocument(doc.uid)}}/>
                </td>
            </tr>
        )
    })

    const documentRequestRows = documentRequests.map((request) => {

        const includedDocuments =  Object.keys(request.documents);
        const documents = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'f8850'].filter(d => includedDocuments.includes(d)).map(d => OnboardingDocumentsReadOnly[d]).join(', ');

        const status = Object.keys(request.documents).reduce((prev, curr) => {
            if (prev === 'Awaiting Employee') {
                return prev;
            } else {
                if (!request.documents[curr].employeeCompleted) {
                    return 'Awaiting Employee';
                } else if (request.documents[curr].requiresEmployer && !request.documents[curr].employerCompleted) {
                    return 'Awaiting Employer';
                } else {
                    return prev;
                }
            }
        }, 'Complete');
        
        return (
            <tr className='cursor-pointer' key={request.uid} onClick={() => {setSelectedDocumentRequest(request); setShowModal(true)}}>
                <td>{moment(request.dateCreated).format('MMM D, YYYY')}</td>
                <td>{documents}</td>
                <td>{status}</td>
                <td>
                    <CustomButton 
                        title='Download' 
                        style={{padding: '0px 6px 0px 6px'}} 
                        label={<FontAwesomeIcon icon={faDownload}/>} 
                        isLoading={request.uid == downloadingBundle} 
                        onClick={(event) => {handleDownloadDocumentRequest(request); event.stopPropagation()}}
                    />
                </td>
            </tr>
        )
    })

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center'}}>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch}/>
                    </InputGroup.Text>
                    <Form.Control type='text' placeholder='Search for a document by title' value={search} onChange={(event) => {setSearch(event.target.value)}}/>
                </InputGroup>
            </div>

            { documentRequests.length > 0 && 
                <QuickTable noWrap title='Document Requests' headers={['Date Created', 'Documents Requested', 'Status', '']} widths={[null, null, null, 1]} rows={documentRequestRows} hover/>
            }
            { documents.length > 0 && 
                <QuickTable title='Documents' rows={documentRows} headers={['Name', 'File Type', 'Date Created', '']} noWrap widths={[null, 1, 1, 1]}/>
            }
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <DocumentRequestDetails documentRequest={selectedDocumentRequest} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}/>
            </Modal>
        </div>
    );



    return isLoading ? <CustomSpinner spinnerHeight='10vh' spinnerWidth='10vh' height='50vh'/> : content;
}