import { faClipboardQuestion, faCouch, faFile, faFileExcel, faFileImage, faFilePdf, faFileVideo, faFileWord, faGraduationCap, faPersonCircleQuestion, faPersonCircleXmark, faPhone, faPlaneDeparture, faTruck, faUserTie } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Col from 'react-bootstrap/Col';
import { pdf } from '@react-pdf/renderer';

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function formatPhoneNumber(str) {
    return '(' + str.slice(0, 3) + ') ' + str.slice(3, 6) + '-' + str.slice(6);
}

export function formatSSN(str) {
    return str.slice(0, 3) + '-' + str.slice(3, 5) + '-' + str.slice(5);
}

export function formatTime(str) {
    return moment('2300-01-01 ' + str).format('hh:mm A')
}

export function formatName(user, type = 0) {
    const middleName = user.middleName ? `${user.middleName} ` : '';
    switch (type) {
      case 0:
        return `${user.firstName} ${middleName}${user.lastName}`;
      case 1:
        return `${user.lastName}${user.firstName}${middleName ? `, ${middleName}` : ''}`;
      case 2:
        return `${user.firstName} ${user.lastName}`;
      default:
        return `${user.firstName} ${middleName}${user.lastName}`;
    }
  }

export function parseAvailability(str) {
    const array = [];
    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek) => {
        array.push(str.includes(dayOfWeek));
    });
    return array;
}

export function encodeAvailability(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let arr = [];
    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            arr.push(daysOfTheWeek[i]);
        }
    }
    return arr.join(',');
}

export function availabilityToString(availability) {
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    let str = ''

    for (let i = 0; i < 7; i++) {
        if (availability[i]) {
            if (str !== '') {
                str += ', ';
            }
            str += daysOfTheWeek[i];
        }
    }
    return str;
}

export function packageAddress(object) {
    object.address = {
        addressIdentifier: object.addressIdentifier,
        thoroughfare: object.thoroughfare, 
        premise: object.premise, 
        locality: object.locality, 
        administrativeArea: object.administrativeArea, 
        postalCode: object.postalCode
    }
}

export function addressComponentsToString(thoroughfare, premise, locality, administrativeArea, postalCode) {
    return addressToString({thoroughfare, premise, locality, administrativeArea, postalCode});
}

export function addressToString(address) {
    if (address) {
        let str = '';

        if (address.thoroughfare) {
            str += address.thoroughfare;
        }
        if (address.premise) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.premise;
        }
        if (address.locality) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.locality;
        }
        if (address.administrativeArea) {
            if (str.length > 0) {
                str += ', ';
            }
            str += address.administrativeArea;
        }
        if (address.postalCode) {
            if (str.length > 0) {
                str += ' ';
            }
            str += address.postalCode
        }
        return str;
    } else {
        return '';
    }
}

export const packetIdentifierDictionary = {
    'w2hf6NLm5BxHtcokpP6xgD': 'Ohio',
    '7CdeqzA5dwXvkEPmpCyzEe': 'Indiana',
    'MKdJtUhFRfKc7NJpyWD5AR': 'Alabama',
    'hLD99dwD6GmAiZSZRZPxCS': 'Kentucky',
    'ahWXWKMLeLWcvn4t4RGMxS': 'Louisiana'
}

export const payTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    py: 'Per Year',
    pm: 'Per Mile',
    ps: 'Per Stop',
    xd: 'Extra Day Wage'
}

export function getUnitsWorked(entry) {
    switch(entry.payType) {
        case 'ph':
            return entry.hoursWorked;
        case 'pm':
            return entry.miles;
        case 'ps':
            return entry.stops;
        default: //pd and py
            return entry.daysWorked;
    }
}

export function getUnitsWorkedTypeString(payType) {
    if (payType === 'ph') {
        return 'Hours Worked';
    } else if (payType === 'pm') {
        return 'Number of Miles';
    } else if (payType === 'ps') {
        return 'Number of Stops';
    } else { //pd and py
        return 'Days Worked';
    }
}

export function getHolidayUnitsTypeString(payType) {
    if (payType === 'ph') {
        return 'Paid Holiday Hours';
    } else if (payType === 'pm') {
        return 'Paid Holiday Miles';
    } else if (payType === 'ps') {
        return 'Paid Holiday Stops';
    } else if(payType === 'pd'){ //pd and py
        return 'Paid Holiday Days';
    } else {
        return 'Holiday Flat Amount'
    }
}

export const stateDictionary = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY'
};

export const ptoTypeDictionary = {
    perFortyHours: 'Per 40 Hours',
    perPayPeriod: 'Per Pay Period'
}

export const bwcCodeDictionary = {
    '7231': 'Driver',
    '8810': 'Admin',
    '7219': 'Linehaul',
    '8380': 'Mechanic',
    '0000': 'Custom'
}

export const employeeTypeDictionary = {
    0: 'Full-Time',
    1: 'Part-Time'
}

export const ptoAccrualTypeDictionary = {
    0: 'Per 40 Hours',
    1: 'Per Pay Period'
}

export const scheduleTypeDictionary = {
    route: {
        label: 'Route',
        icon: faTruck,
        color: '#ffa496'
    },
    driverGeneratedRoute: {
        label: 'Driver Generated Route',
        icon: faTruck,
        color: '#ffa496'
    },
    managerOnDuty: {
        label: 'Manager On Duty',
        icon: faUserTie,
        color: '#73ffcc'
    },
    paidTraining: {
        label: 'Paid Training',
        icon: faGraduationCap,
        color: '#96c7ff'
    }, 
    callOff: {
        label: 'Call Off',
        icon: faPhone,
        color: '#bc96ff'
    }, 
    dayOff: {
        label: 'Day Off',
        icon: faCouch,
        color: '#8affa5'
    }, 
    pto: {
        label: 'Paid Time Off',
        icon: faPlaneDeparture,
        color: '#b2ff96',
    },
    requestOff: {
        label: 'Request Off',
        icon: faClipboardQuestion,
        color: '#eded77',
    },
    rejectedRequest: {
        label: 'Rejected Request',
        icon: faPersonCircleXmark,
        color: 'lightgray',
    },
    noShow: {
        label: 'No-Show',
        icon: faPersonCircleQuestion,
        color: '#a992b0'
    },
}

export const titleDictionary = {
    Driver: {
        color: 'lightgray',
    },
    BC: {
        color: 'lightblue',
    },
    AO: {
        color: 'orange'
    }
}



export function durationToString(durationInMinutes) {

    let totalMinutes = durationInMinutes;
    
    let days = 0;
    let hours = 0;
    let minutes = 0;

    while (totalMinutes >= 1440) {
        days += 1;
        totalMinutes -= 1440;
    }
    while (totalMinutes >= 60) {
        hours += 1;
        totalMinutes -= 60;
    }
    minutes = totalMinutes;
    
    const arr = [];
    if (days) {
        arr.push(days > 1 ? days + ' days' : '1 day');
    }
    if (hours) {
        arr.push(hours > 1 ? hours + ' hours' : '1 hour');
    }
    if (minutes) {
        arr.push(minutes > 1 ? minutes + ' minutes' : '1 minute');
    }

    const str = arr.join(', ');

    return str;
}


export function calculateHourlyWage(payType, payRate) {
    if (payType === 'pd') {
        return payRate / 8.0;
    } else if (payType === 'py') {
        return ((payRate / 52.0) / 40.0) / 8.0;
    }
}


export function validateDecimal(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateInteger(value) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}


export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function getStartOfWeek() {
    return moment().day() === 6 ? moment() : moment().startOf('week').subtract(1, 'days');
}

export function formatDateRange(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        if (moment(startDate).isSame(moment(endDate), 'year')) {
            if (moment(startDate).isSame(moment(endDate), 'month')) {
                if (moment(startDate).isSame(moment(endDate), 'day')) {
                    return moment(startDate).format('MMM D, YYYY');
                } else {
                    return `${moment(startDate).format('MMM D')}-${moment(endDate).format('D, YYYY')}`;
                }
            } else {
                return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D, YYYY')}`;
            }
        } else {
            return `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}`;
        }
    }
}

export function wrapElementInCol(element, breakpoints) {
    return (
        <Col style={{marginBottom: 8}} xs={breakpoints.xs} sm={breakpoints.sm} md={breakpoints.md} lg={breakpoints.lg} xl={breakpoints.xl} xxl={breakpoints.xxl}>
            {element}
        </Col>
    )
}

export function dateRangeToString(startDate, endDate) {
    if (!startDate && !endDate) {
        return 'All Time';
    } else if (!startDate) {
        return 'Before ' + moment(endDate).format('MMM D, YYYY');
    } else if (!endDate) {
        return 'After ' + moment(startDate).format('MMM D, YYYY');
    } else {
        return moment(startDate).format('MMM D, YYYY') + ' - ' + moment(endDate).format('MMM D, YYYY');
    }
}

export function dateIsInRange(date, startDate, endDate) {
    if (!startDate && !endDate) {
        return true;
    } else if (!startDate) {
        return moment(endDate).diff(moment(date), 'days') >= 0;
    } else if (!endDate) {
        return moment(date).diff(moment(startDate), 'days') >= 0;
    } else {
        return moment(date).isBetween(moment(startDate), moment(endDate), 'days', '[]');
    }
}



export function getFileIcon(documentType) {
    switch (documentType) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'heic':
        case 'heic':
            return faFileImage;
        case 'pdf':
            return faFilePdf;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'rtf':
        case 'txt':
        case 'tex':
        case 'wpd':
            return faFileWord;
        case 'xlsx':
        case 'xls':
        case 'xlsm':
        case 'csv':
        case 'tsv':
        case 'xml':
            return faFileExcel;
        case 'mp4':
        case 'avi':
        case 'wmv':
        case 'mov':
        case 'flv':
        case 'avchd':
        case 'mpeg':
            return faFileVideo;
        default:
            return faFile;
    }
}

export async function saveFile(name, base64) {
    const fetchResponse = await fetch(base64);
    const blob = await fetchResponse.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = name;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
}

export function sortTimes(a, b) {
    const aNum = parseInt(a.slice(0, 2) + a.slice(3, 5));
    const bNum = parseInt(b.slice(0, 2) + b.slice(3, 5));
    return aNum < bNum ? -1 : aNum > bNum ? 1 : 0;
}

export function getTimeOnDayAsMoment(time, date) {
    return moment(moment(date).format('YYYY-MM-DD') + ' ' + time);
}

export const permissionsTemplate = [
    {
        permissionName: 'hr_profile',
        permissionTitle: 'HR Profile',
        description: 'Access to employee profile information on all employees in the company',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_schedule',
        permissionTitle: 'HR Schedule',
        description: 'Access to employee schedule items; ability to accept requests for time off and ability to report call-offs and no-shows',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_timesheet',
        permissionTitle: 'HR Timesheet',
        description: 'Access to employee timesheet records; ability to view, modify, create, and delete timesheet entries',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_pay',
        permissionTitle: 'HR Pay',
        description: 'Access to employee pay information; ability to view current and historic pay information and define a new position for the employee',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_safety',
        permissionTitle: 'HR Safety',
        description: 'Access to employee safety records; ability to view, create, and revise accidents, incidents, moving violations, and ride-alongs',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_bwc',
        permissionTitle: 'HR BWC',
        description: 'Access to employee BWC records; ability to view, create, and revise BWC reports',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_documents',
        permissionTitle: 'HR Documents',
        description: 'Access to employee documents (images, pdfs, word documents, etc.); ability to download existing documents and upload new documents',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'hr_permissions',
        permissionTitle: 'HR Permissions',
        description: 'Access to employee permissions; ability to view and modify permissions',
        driver: '0',
        bc: '0',
        ao: '1',
    },
    {
        permissionName: 'payroll',
        permissionTitle: 'Payroll',
        description: 'Access to company payroll; ability to view current and historic payroll information and approve payroll periods',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'scheduleMatch',
        permissionTitle: 'Schedule Match',
        description: 'Access to company scheduling; ability to view, create, modify, and delete scheduled routes, paid training, and managers on duty.',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'myCompany',
        permissionTitle: 'Company Resources & Settings',
        description: 'Access to company resources and settings; ability to view, create, modify, and deactivate CSAs, work areas, and vehicles; ability to modify company settings (ex. company address).',
        driver: '0',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'profile',
        permissionTitle: 'Profile',
        description: 'Ability to view his or her own personal information, pay information, and ability to change his or her password',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'timeClock',
        permissionTitle: 'Time Clock',
        description: 'Ability to view his or her own timesheet records, clock-in, and clock-out',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'mySchedule',
        permissionTitle: 'My Schedule',
        description: 'Ability to view his or her own personal calendar of routes, time off, etc.',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'daysOff',
        permissionTitle: 'Days Off',
        description: 'Ability to view his or her own history of time off requests and their statuses (approved, rejected, pending); ability to request time off',
        driver: '1',
        bc: '1',
        ao: '1',
    },
    {
        permissionName: 'reports',
        permissionTitle: 'Reports',
        description: 'View company reports, such as timesheets, budget information, user lists, etc.',
        driver: '0',
        bc: '0',
        ao: '1',
    },
]

let fakeUID = 0;

export function getFakeUID() {
    fakeUID--;
    return fakeUID;
}

export const taxDocumentDictionary = {
    'a4': 'A4 (Alabama)',
    'wh4': 'WH-4 (Indiana)',
    'k4': 'K-4 (Kentucky)',
    'ks4': 'K-4 (Kansas)',
    'l4': 'L-4 (Louisiana)',
    'miw4': 'MI-W4 (Michigan)',
    'mw4': 'MW-4 (Montana)',
    'it2104': 'IT-2104 (New York)',
    'it4': 'IT-4 (Ohio)',
    'rev419': 'REV-419 (Pennsylvania)',
    'va4': 'VA-4 (Virginia)',
    'wt4': 'WT-4 (Wisconsin)'
}

export const OnboardingDocumentsShort = {
    w4: 'W-4',
    i9: 'I-9',
    f8850: 'Form 8850',
    dl: 'Photo of Driver\'s License',
    ssn: 'Photo of Social Security Card',
}

export const DirectDepositDocuments = {
    'dd': 'Direct Deposit Authorization',
    'mdd': 'Multiple Direct Deposit Authorization'
}

export const OnboardingDocuments = {
    'w4': 'W-4 (Employee’s Withholding Certificate)',
    'i9': 'I-9 (Employment Eligibility Verification)',
    'f8850': 'Form 8850 (for Work Opportunity Credit)',
}

export const OnboardingDocumentsReadOnly = {
    'archivedDocument': 'Archived Document',
    'offerLetter': 'Offer Letter', 
    "dl": 'Driver\'s License',
    "ssn": 'Social Security Card',
    ...DirectDepositDocuments,
    ...OnboardingDocuments,
    ...taxDocumentDictionary
}

export async function downloadBase64(base64, title) {
    const response = await fetch(base64);
    const blob = await response.blob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = title;        
    document.body.appendChild(elem);
    elem.click();        
    document.body.removeChild(elem);
}

export function naturalSort (array, type){
    var a, b, a1, b1, rx=/(\d+)|(\D+)/g, rd=/\d+/;
    return array.sort(function(as, bs){
        a= String(as[type]).toLowerCase().match(rx);
        b= String(bs[type]).toLowerCase().match(rx);
        while(a.length && b.length){
            a1= a.shift();
            b1= b.shift();
            if(rd.test(a1) || rd.test(b1)){
                if(!rd.test(a1)) return 1;
                if(!rd.test(b1)) return -1;
                if(a1!= b1) return a1-b1;
            }
            else if(a1!= b1) return a1> b1? 1: -1;
        }
        return a.length- b.length;
    });
}


export function validateDecimalFixed(value, decimalPlaces) {
    return parseFloat(validateDecimal(value).toFixed(decimalPlaces));
}

export async function reactPdfToBase64(doc) {
    const blob = await pdf(doc).toBlob();
    const thing = await new Promise((resolve, _reject) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
    return thing;
  }